$defaultWidth: 2.35rem;

.button{
    display: flex;
    min-width: $defaultWidth;
    min-height: $defaultWidth;
    align-items: center;
    justify-content: center;
    width: fit-content;
    user-select: none;
    background-color: rgb(78 78 78 / 5%);
    padding: 0 0.8rem;
    border-radius: 5px;
    transition: background-color var(--animationSpeed) ease;
    border: none;
    color: inherit;
    font: inherit;

    &[data-primary]{
        background-color: var(--colorNeutral);
        color: var(--colorTextAlt);

        &:hover{
            background-color: #0000002b;
        }
    }

    &:hover{
        background-color: #0000002b;
    }
    
    &.btn_round{
        border-radius: 1000px;
        padding: 0;
    }

    .button_notification {
        position: absolute;
        background-color: var(--colorPrimary);
        color: white;
        border-radius: 50%;
        height: 20px;
        width: 20px;
        left: 30px;
        top: -5px;
    }
}