@import '/src/styles/Responsive.scss';

.page_menu{
    position: sticky;
    background: #fff;
    width: 100%;
    height: var(--navBarHeight);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    text-transform: uppercase;
    color: #707070;
    z-index: 10;
    top: 0;

    font-family: var(--accentFontSecondary);

    padding: 0 5rem;
    @include size_M {    
        padding: 0 2rem;
    }
    @include size_XS {    
        padding: 0 1rem;
    }

    .small_menu_strip{
        height: 100%;
        
        & .menu_logo{
            height: 100%;
            width: 200px;
            padding: 0.8rem 0;

            @include size_M{
                width: unset;
            }

            .menu_logo_img_sm {
                display: none;
            }

            & a {
                height: 100%;

                & img{
                    height: 100%;

                    @include size_S {
                        &.menu_logo_img_lg {
                            display: none;
                        }
                        &.menu_logo_img_sm {
                            display: inline;
                        }
                    }
                }
            }
        }
    }

    @include size_S { 
        &.menu_opened{
            width: 100%;
            height: 100%;
            background-color: var(--colorBackground);
            flex-direction: column;
            position: fixed;

            .menu_links{
                display: flex;
                font-size: 2rem;
                width: 100%;
                height: calc(100% - var(--navBarHeight));
                flex-direction: column;
                align-items: center;
                justify-content: center;
                user-select: none;
                gap: 0.8rem;
            }
        }

        & .small_menu_strip{
            width: 100%;
            height: var(--navBarHeight);
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    .menu_links{
        display: flex;
        align-items: center;

        @include size_S {    
            display: none;
        }

        .menu_item, .menu_item a{
            transition: var(--animationSpeed) all;
        }
        .menu_item:hover, .menu_item a:hover{
            opacity: 0.7;
        }
        .menu_item:not(:last-of-type){
            margin-right: 2rem;

            @include size_S {
                margin-right: 0;
            }
        }
        .menu_item a.active{
            border-bottom: 2px solid var(--colorPrimary);
            color: var(--colorText);
        }
    }

    .menu_toggle{
        display: none;
        cursor: pointer;
        font-size: 1.6rem;

        @include size_S {    
            display: inline;
        }
    }

    .nav_actions_box{
        width: 200px;

        @include size_M{
            width: unset;
        }

        @include size_S {
            margin-left: 1rem;
        }

        @include size_XS {
            .language_icon {
                //display: none;
            }
        }
        
        img {
            width: 1.5rem;
        }
    }

    &.menu_opened {
        & .nav_actions_box {
            margin-bottom: 1rem;
            margin-left: 0;
        }
    }
}