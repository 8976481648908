@import '/src/styles/Responsive.scss';

.box.flex{
    display: flex;
    align-items: center;
    justify-content: center;

    &.flex_end{
        justify-content: flex-end;
    }
}

@for $i from 2 through 4 {
    .box.flex_grid_#{$i} {
        display: grid !important;
        grid-template-columns: repeat(#{$i}, 1fr);
        grid-auto-rows: min-content;

        @include size_M{
            grid-template-columns: repeat(#{$i - 1}, 1fr);
        }
        @include size_S{
            grid-template-columns: 1fr;
        }
    }
}