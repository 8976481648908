.about_us_section_title{
    font-size: 18px;
    font-weight: bold;
    line-height: 3;
}

.about_us_section_subtitle{
    font-weight: bold;
    text-decoration: underline;
}

.about_us_section_text{
    white-space: pre-line;
    line-height: 3;
}

.about_us_file_download_button {
    
    padding: 10px 15px;
    background: #0000000a;
    border-radius: 10px;
    display: flex;
    align-items: center;
    width: fit-content;
    gap: 10px;

    .about_us_file_download_button_icon{
        color: #E80E02;
        font-size: 22px;
    }
}